<template>
	<div class="box">
		<top-nav @back="back">创建保单</top-nav>
		<div class="main_title product_name" v-text="productInfo.proName"></div>
		<div v-for="(group, index) in formGroupList" :key="index">
			<insuranceForm v-if="group.groupCode === 'product'" :group="group" ref="productForm" />
			<insuranceForm v-if="group.groupCode === 'insure'" :group="group" ref="insureForm" />
			<assureForm v-if="group.groupCode === 'assured'" :group="group" @getInsureForm="getInsureForm" ref="assuredForm" />
			<div class="line" v-if="['product', 'insure'].includes(group.groupCode)"></div>
		</div>
		<readTrems v-if="formGroupList.length" @getReadOk="getReadOk" :proId="proId" :comboId="comboId" :insProductInformation2Vo="insProductInformation2Vo" />

		<price-buy-btn v-show="buyBtnShow" :price="allPrice" @buy="buy"></price-buy-btn>
	</div>
</template>

<script>
import { http_getProductConfig, http_insuredOrder } from '../../request/insureV2';
import insuranceForm from './components/insuranceForm.vue';
import assureForm from './components/assureForm.vue';
import readTrems from '@/components/readTrems.vue';
import { Toast ,Dialog} from 'vant';
export default {
	name: 'createOrderV2',
	components: {
		insuranceForm,
		assureForm,
		readTrems,
		[Toast.name]: Toast,
		[Dialog.Component.name]: Dialog.Component,

	},
	data() {
		return {
			formGroupList: [],
			comboList: [],
			productInfo: {},
			insProductInformation2Vo: {},
			readOk: false,
			buyBtnShow: true,
			fieldParent: {},
			proId: '',
			comboId: '',
		};
	},
	async created() {
		Toast.loading({
			duration: 0, // 持续展示 toast
			forbidClick: true,
			message: '加载中',
		});
		const { proId, comboId } = this.$route.params;
		this.proId = proId;
		this.comboId = comboId;
		this.$store.dispatch('getCodeCategory');
		await this.$store.dispatch('getCode');
		//获取职业信息
		await this.$store.dispatch('getCareer');
		this.$store.dispatch('getCountry');
		this.getData();
	},
	provide() {
		return {
			comboId: () => this.comboId,
			proId: () => this.proId,
			comboList: () => this.comboList,
			productInfo: () => this.productInfo,
			insProductInformation2Vo: () => this.insProductInformation2Vo,
		};
	},
	computed: {
		allPrice() {
			return this.$store.state.insuranceV2.allPrice;
		},
	},
	beforeRouteLeave(to, from, next) {
		this.scrollY = document.querySelector('html').scrollTop;
		if (to.name === 'productDetail') {
			khs.stop();
			const store = from.matched[0].instances.default.$store;
			store.commit('delete_keepAlivePage', 'createOrderV2');
			store.commit('setReset');
		}
		next();
	},
	beforeRouteEnter(to, from, next) {
		if (from.name === 'productDetail') {
			const store = from.matched[0].instances.default.$store;
			store.commit('add_keepAlivePage', 'createOrderV2');
		}
		next(vm => {
			vm.$nextTick(() => {
				document.querySelector('html').scrollTop = vm.scrollY;
			});
		});
	},
	methods: {
		//获取数据
		async getData() {
			const res = await http_getProductConfig({ proId: this.proId });
			// 获取省市区
			await this.$store.dispatch('getArea', res.insurer2Vo.insurerCode);
			this.formGroupList = res.insFormGroup2VoList;
			this.comboList = res.comboList;
			this.productInfo = res.insProduct2Vo;
			this.insProductInformation2Vo = res.insProductInformation2Vo;

			this.setFieldParent(this.formGroupList);
			Toast.clear();
		},

		// 收集所有字段的父级字段(由于字段的上级报文字段只在与提交订单接口或回显接口交互的时候使用，所以在此处统一处理)
		setFieldParent(list) {
			let result = {};
			list.forEach(group => {
				group.insForm2VoList.forEach(field => {
					if (field.parentCode) {
						result[field.fieldName] = field.parentCode;
					}
				});
			});
			this.fieldParent = result;
		},
		//获取投保人信息
		getInsureForm(cb) {
			this.$refs.insureForm[0].getData().then(res => {
				cb(res);
			});
		},
		// 勾选条款
		getReadOk(val) {
			this.readOk = val;
		},
		async getFormData() {
			let product = await this.$refs.productForm[0].getData();
			// 投保人信息
			let insure = await this.$refs.insureForm[0].getData();
			// 被保人信息
			let assured = await this.$refs.assuredForm[0].getData();
			return Promise.resolve({ product, insure, assured });
		},
		back() {
			this.$router.push({ name: 'productDetail' });
		},
		async buy() {
			if (!this.readOk) {
				Toast('请先勾选条款');
				return;
			}
			// 获取购买信息
			const { product, insure, assured } = await this.getFormData();
			if (assured.assured.length === 0) {
				Toast('请填写被保人信息');
				return;
			}
			let send = {};
			// 产品信息
			send.insuranceProductList = [{ proId: product.product.proId, comboId: product.product.comboId }];
			// 订单信息
			send.insuranceBill = {
				billId: '',
				enableDate: product.product.start,
				disEnableDate: product.product.end,
				premium: Number(this.allPrice),
				assuredSendMsg: assured?.assuredSendMsg ? 1 : 0,
				platform: 'H5',
			};
			Object.assign(send.insuranceBill, product.insurance);

			// 投保人信息
			send.insuranceApplicant = {};
			Object.assign(send.insuranceApplicant, insure);
			// 被保人信息
			send.insuranceInsuredList = assured.assured.map(item => {
				return {
					name: item.name,
					certificateType: item.certificateType,
					certificateContent: item.number,
					birthday: item.birthday,
					sex: item.sex,
					mobile: item.phone,
					englishName: item.englishName,
					profession:item.profession,
					address:item.address,
					workplace:item.workplace,
					insuranceInsuredPriceList: [
						{
							proId: product.product.proId,
							comboId: product.product.comboId,
							priceId: product.product.priceId,
						},
					],
				};
			});
			// 扩展字段
			send.extentionVoList = [];

			// 从订单信息、投保人信息中删除有父级报文字段的字段
			let parentFieldList = Object.keys(this.fieldParent);
			let parentFeildValue = {};
			for (const key in send.insuranceBill) {
				if (Object.hasOwnProperty.call(send.insuranceBill, key)) {
					const val = send.insuranceBill[key];
					if (parentFieldList.includes(key)) {
						parentFeildValue[key] = val;
						delete send.insuranceBill[key];
					}
				}
			}
			for (const key in send.insuranceApplicant) {
				if (Object.hasOwnProperty.call(send.insuranceApplicant, key)) {
					const val = send.insuranceApplicant[key];
					if (parentFieldList.includes(key)) {
						parentFeildValue[key] = val;
						delete send.insuranceApplicant[key];
					}
				}
			}
			// 把有父级报文字段的数据再对号入座
			for (const key in parentFeildValue) {
				if (Object.hasOwnProperty.call(parentFeildValue, key)) {
					const val = parentFeildValue[key];
					if (this.fieldParent[key] === 'extentionVoList') {
						let str = val;
						if (Array.isArray(val)) {
							str = val.join(',');
						} else if (typeof str !== 'string') {
							str = JSON.stringify(val);
						}
						// 父级报文字段是扩展字段时
						send.extentionVoList.push({
							colName: key,
							colValue: str,
						});
					} else {
						if (!send[this.fieldParent[key]]) {
							send[this.fieldParent[key]] = {};
						}
						send[this.fieldParent[key]][key] = val;
					}
				}
			}
			Toast.loading({
				duration: 0, // 持续展示 toast
				forbidClick: true,
				message: '提交订单中',
			});
			http_insuredOrder(send).then(res => {
				if(res.success){
					// 不拆单
					if (!res.data.isSplit) {
						khs.finish(res.data.mainBill.id);
						this.$store.commit('set_billId', res.data.mainBill.id);
						this.$router.push({
							name: 'orderPay',
							params: {
								isRedict: false,
							},
						});
					} else {
						let orderId = res.data.mainBill.id;
						khs.finish(orderId);
						this.$store.commit('set_billId', res.data.billIdList.join(','));

						this.$router.push({
							name: 'orderPay',
							params: {
								isRedict: false,
							},
						});
					}
				}else{
					if(res.code=='4400'&&res.data){
						const message=res.data[0]?.name+":"+res.data[0]?.errorDesc
							Dialog.confirm({
								title: '错误提示',
								message: message,
								showCancelButton:false,
								confirmButtonColor:"#2594ef"
							}).then(()=>{})
					}else{
						Toast.fail(res.message);
					}
				}
			}).finally(()=>{
				Toast.clear();
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.box {
	font-size: 0.3rem;
}
.main_title {
	font-size: 0.3rem;
	color: $font_color_main;
	font-weight: bold;
}
.product_name {
	padding: 0.4rem 0.3rem;
	line-height: 0.3rem;
}
.line {
	width: 90%;
	height: 1px;
	background-color: $b_border_color;
	margin: 0.3rem auto;
}
</style>
